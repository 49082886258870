import React from "react";
import CharlesMeta from "components/common/meta/CharlesMeta";
import Segment from 'utils/segment'
import BlogLayout from "components/common/blog/BlogLayout";
import ProfileHeader from "components/Author/ProfileHeader";
import AuthorPressBanner from "components/Author/AuthorPressBanner";
import AuthorSpecialties from "components/Author/AuthorSpecialties";
import ConsultationInfos from "components/Author/ConsultationInfos";
import AuthorCircle from "components/Author/AuthorCircle";
import './styles.sass';
import data from './data.js';
import ProgramPresentation from "components/Author/ProgramPresentation";
import GilbertPresentation from "components/Author/GilbertPresentation";

// TODO: stop relying on wordpress for that component
class Page extends React.Component {
  render() {
    const metaData = data["metaData"]
    const metaTitle = metaData ? metaData.title : page.title
    const metaDescription = metaData ? metaData.description : page.title

    return (
      <BlogLayout showReinsuranceInFooter={true}>
        <CharlesMeta
          title={metaTitle}
          description={metaDescription}
          segmentCategory={Segment.CATEGORIES.BLOG}
        />
        <ProfileHeader />
        <AuthorPressBanner />
        <AuthorSpecialties />
        <ConsultationInfos />
        <ProgramPresentation data={data} />
        <GilbertPresentation data={data["gilbertPresentation"]} />
      </BlogLayout>
    );
  }
}

export default Page;
