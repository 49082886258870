import React from 'react';
import Section from 'components/common/Section';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VideoThumbnail from 'components/common/VideoThumbnail';

const VideoSection = (video) => {
  const shouldShowTexAside = video.summary || video.intro;
  return (
    <div className="">
      { video.titleDisplay === true && (
        <Row>
          <Col>
            <h2 className="h1 section-title h2-video-section">{video.title}</h2>
          </Col>
        </Row>
      )}
      <Row>
        {shouldShowTexAside && (
          <Col
            xs={{ order: 1, span: 12 }}
            md={{ order: 1, span: 5 }}
            className="align-self-start"
          >
            {video.intro && (
              <span>{video.intro}</span>
            )}
            {video.summary && (
              <ul className="d-none d-md-block mt-4 how-it-works-steps">
                {video.summary.map((element) => (
                  <li key={element.id}>
                    <span className="d-block d-md-inline" />
                    <span className="font-weight-bold">{element.time}</span>
                    <p className="small font-weight-lighter">
                      {element.text}
                    </p>
                  </li>
                ))}
              </ul>
            )}
          </Col>
        )}
        <Col
          xs={{ order: 2, span: 12 }}
          md={{ order: 2, offset: 0, span: 12 }}
          className="align-self-center text-center"
          style={{ maxWidth: '635px', margin: 'auto' }}
        >
          <VideoThumbnail
            data={video}
            showPlayIcon
          />
        </Col>
      </Row>
    </div>
  );
};

export default VideoSection;
