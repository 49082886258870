import React from "react";
import Helmet from "react-helmet";
import { Charles } from "types/index"
import { useStaticQuery, graphql } from "gatsby";
import { VideoObject } from "schema-dts";

type Props = {
  videoId: string
}

const StructuredVideo = ({ videoId }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      allAirtableYoutubeVideos {
        nodes {
          id
          data {
            video_id
            title
            duration
            description
            published_at
          }
        }
      }
    }
  `);

  const item: Charles.YoutubeVideoRecord = data.allAirtableYoutubeVideos.nodes.find((videoNode: Charles.YoutubeVideoRecord) => videoNode.data.video_id == videoId);
  if (!item) {
    return null;
  }
  const videoData: Charles.YoutubeVideo = item.data
  // https://developers.google.com/search/docs/data-types/video#video-object
  const schema : VideoObject = {
    "@type": "VideoObject",
    name: videoData.title,
    description: videoData.description,
    embedUrl: `https://www.youtube.com/embed/${videoId}`,
    thumbnailUrl: [
      `https://img.youtube.com/vi/${videoId}/0.jpg`,
      `https://img.youtube.com/vi/${videoId}/1.jpg`,
      `https://img.youtube.com/vi/${videoId}/2.jpg`,
      `https://img.youtube.com/vi/${videoId}/3.jpg`,
    ],
    /* @ts-ignore Weird bug of TS that thinks uploadDate should be a string */
    uploadDate: videoData.published_at,
    duration: videoData.duration,
  };

  return (
    <Helmet defer={false}>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

export default StructuredVideo;
