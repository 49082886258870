import React from 'react';
import VideoSection from "components/common/VideoSection";
import Cta from 'components/common/Cta';
import { Row, Container } from "react-bootstrap";

import './styles.sass';

class AuthorCircle extends React.Component {
  render() {
    const video = {
      filename: 'videos/thumbnails/video-cercle.jpg',
      alt: 'Entrez dans le Cercle',
      intro: '',
      video: 'https://www.youtube.com/embed/ttXgshx3Hys?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'ttXgshx3Hys',
        title: 'Entrez dans le Cercle',
        description: null,
        duration: '0:48',
        uploadDate: (new Date(2023, 8, 3)).toISOString()
      },
    }
    
    return (

    <div className="author-circle" key="circle-bloc" id="author-cercle-anchor">
      <Container className='author-circle-container'>
        <Row className='d-flex justify-content-center'>
            <h2 className='author-circle-title'>Améliorez durablement votre vie de couple</h2>
            <p className='author-circle-description'>Le Dr Bou  Jaoudé a créé une méthode pour vous épanouir dans votre couple mêlant son expertise et le pouvoir de la parole</p>
            <div className='author-circle-video'>
              <VideoSection {...video} />
            </div>
              <Cta
                  className="new-white-without-border author-circle-button"
                  text="Je découvre Cercle"
                  path="https://cercle.charles.co/"
              />
        </Row>
      </Container>
    </div>
    );
  }
}

export default AuthorCircle;
