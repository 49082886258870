import React from "react";
export default {
  metaData: {
    title: "Dr Gilbert Bou Jaoudé : Médecin sexologue et Thérapeute de couple | Charles",
    description: "Retrouvez dès maintenant les différents programmes en ligne créés par le docteur Gilbert Bou Jaoudé. Et consultez sans tarder avec lui ou son équipe médicale composée de médecins sexologues."
  },
  programs: [
    {
      slug: "master",
      title: "Améliorez votre érection avec le programme MASTER",
      description: "Le programme érection est un concentré de techniques physiques et mentales qui vous permettront de comprendre votre fonctionnement érectile. L'objectif ? Apprendre à retrouver et à mieux gérer vos érections et réduire le stress qui pourrait entacher votre sexualité.",
      video: {
        titleDisplay: false,
        filename: "videos/thumbnails/thumbnail-master-program.jpg",
        alt: "Trailer Programme Master",
        title: "Trailer Programme Master",
        video: "https://cdn.jwplayer.com/players/R9RzLQdw-KdT3I1iC.html"
      }
    },
    {
      slug: "time",
      title: "Maîtrisez votre éjaculation avec le programme TIME ",
      description: "Le programme éjaculation est le tout premier programme visant à régler le trouble de l'éjaculation précoce de manière naturelle, via des exercices physiques et mentaux, le tout sans limiter le plaisir que vous prenez lors des rapports sexuels.",
      video: {
        titleDisplay: false,
        filename: "videos/thumbnails/thumbnail-time-program.jpg",
        alt: "programme-time",
        title: "programme time",
        video: "https://cdn.jwplayer.com/players/nhdbCoHz-KdT3I1iC.html"
      }
    }
  ],
  gilbertPresentation: {
    title: "Qui est le Dr Gilbert Bou Jaoudé ?",
    description: (
      <div>
        Bonjour, je suis Gilbert Bou Jaoudé, médecin sexologue et thérapeute de couple depuis plus de 25 ans. Tout au long de ma carrière, j’ai eu la chance d’<b>aider des milliers de patients à résoudre leurs troubles sexuels.</b> C’est justement à partir de cette expérience que j’ai créé les programmes TIME et MASTER pour vous permettre de retrouver une sexualité épanouie grâce à des exercices simples et efficaces ! <br /> <b>Découvrez gratuitement</b> les premiers épisodes des programmes TIME et MASTER pour commencer dès maintenant à reprendre le contrôle sur votre vie sexuelle.
        Si vous le souhaitez, je reste disponible pour une téléconsultation directement sur Charles.co !
      </div>)
  }
}
