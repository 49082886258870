import React from "react";
import Image from "components/common/utils/Image";
import "./styles.sass";

const AuthorPressBanner = () => (
  <div className="author-press-banner">
    <div className="h4 press-banner-separator">Vu et entendu sur</div>
    <div className="text-center interview-carousel">
      <Image
        filename="press/m6.svg"
        title="logo M6"
        alt="logo M6"
      />
      <Image
        filename="press/mag.svg"
        title="logo Le mag de la santé"
        alt="gq-logo" />
      <Image
        filename="press/france-info.svg"
        title="logo France info"
        alt="logo France info"
      />
    </div>
  </div>
);

export default AuthorPressBanner;
