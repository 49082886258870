import React, { Component, useEffect, useState } from 'react';
import Cta from 'components/common/Cta';
import Image from 'components/common/utils/Image';
import YellowUnderline from "images/author/yellow-vector.svg";
import './styles.sass';

const ProfileHeader = () => {
  const headerImageAlt = "Gilbert Bou Jaoudé - médecin sexologue et thérapeute de couple"

  return (
    <>
      <div className="sm-profile-description">
        <div className="h1 sm-profile-title">Dr Gilbert <p>Bou&nbsp;Jaoudé</p><YellowUnderline className="yellow-underline" /></div>
        <p className="sm-inner-description">
          Avec plus de 25 ans d'expérience en médecine sexuelle et thérapie de couple, le Dr Bou&nbsp;Jaoudé saura être à l’écoute et prendre soin de vous tout au long de votre parcours chez Charles.
        </p>
        <div className='sm-profile-anchor'>
          <span>Nos spécialités :</span>
          <div>
            <Cta
              className="new-primary-cta sm-profile-anchor-cta"
              text="Santé sexuelle"
              path={`#author-speciality-anchor`}
            />
            <Cta
              className="new-primary-cta sm-profile-anchor-cta"
              text="Poids"
              path="https://www.joincercle.com"
            />
          </div>
        </div>
      </div>
      <div className="author-profile-header">
        <div className="profile-picture">
          <Image filename="author/gilbert-header.png" alt={headerImageAlt} title={headerImageAlt} />
        </div>
        <div className="profile-description">
          <h1 className="profile-title">Dr Gilbert <p>Bou Jaoudé</p><p><YellowUnderline className="yellow-underline" /></p></h1>
          <p className="inner-description">
            Avec ses 25 années d'expérience en sexologie et médecine sexuelle, le Dr Bou Jaoudé et ses collègues vous accompagnent.
          </p>
          <div className='profile-anchor'>
            <span>Nos spécialités :</span>
            <div>
              <Cta
                className="new-primary-cta profile-anchor-cta"
                text="Santé sexuelle"
                path={`#author-speciality-anchor`}
              />
              <Cta
                className="new-primary-cta profile-anchor-cta"
                text="Perte de poids"
                path="https://www.joincercle.com"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileHeader;
