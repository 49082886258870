import React from 'react';
import Image from 'components/common/utils/Image';
import config from 'utils/siteConfig';
import './styles.sass';
import { Container } from "react-bootstrap";

class ConsultationInfos extends React.Component {
  render() {
    const headerImageAlt = "Gilbert Bou Jaoudé - médecin sexologue et thérapeute de couple"
    return (
      <div className="consultation-infos">
        <Container>
          <div className="infos-row row">
            <div className="col-md-4 infos-item">
              <Image
                filename="author/small-economies.svg"
                alt="icon economies"
                title="icon-economies"
              />
              <p className="info-item-text"><span className="item-bolder-text">{config.consultationPrice}€</span> (pris en charge par la majorité des mutuelles)</p>
            </div>
            <div className="col-md-4 infos-item">
              <Image
                filename="author/large-rendez-vous-confirme.svg"
                alt="icon rendez-vous"
                title="icon-rendez-vous"
              />
              <p className="info-item-text">Un rendez-vous <span className="item-bolder-text">dans la journée</span> (même le dimanche)</p>
            </div>
            <div className="col-md-4 infos-item">
              <Image
                filename="author/small-assistance.svg"
                alt="icon assistance"
                title="icon-assistance"
              />
              <p className="info-item-text">Suivi patient Lundi au vendredi (9h30 - 19h)</p>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default ConsultationInfos;
