import Image from "components/common/utils/Image";
import React from "react";
import "./styles.sass";
import Container from "react-bootstrap/Container";

const IndexPage = ({ data }: any) => {

  return (
    <div className="gilbert-presentation-component">
      <Container>
        <div className="gilbert-presentation-background">
          <h2>{data.title}</h2>
          <div className="gilbert-presentation-wrapper">
            <div className="gilbert-presentation-text">
              <Image filename="icons/new-quotes.svg" alt="Parenthèse" className="gilbert-presentation-text-quote" />
              <div className="gilbert-presentation-description">{data.description}</div>
            </div>
            <div className="gilbert-presentation-image">
              <Image filename="author/gilbert-hand.png" alt="Consultation Dr Gilbert Bou Jaoudé" />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default IndexPage;
