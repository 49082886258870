import React from 'react';
import Cta from 'components/common/Cta';
import Image from 'components/common/utils/Image';
import { Link } from 'gatsby';
import './styles.sass';
import { Row, Container } from "react-bootstrap";

class AuthorSpecialties extends React.Component {
  render() {
    const headerImageAlt = "Gilbert Bou Jaoudé - médecin sexologue et thérapeute de couple"
    const pathologies = [
      {
        name: "Troubles de l'érection",
        path: "/pathologies/sante-sexuelle/problemes-erection/",
        classes: "specialties-item underlined-item"
      },
      {
        name: "Éjaculation précoce",
        path: "/pathologies/sante-sexuelle/ejaculation-precoce/",
        classes: "specialties-item underlined-item"
      },
      {
        name: "Baisse de libido",
        path: "/pathologies/sante-sexuelle/baisse-de-libido/",
        classes: "specialties-item underlined-item"
      },
      {
        name: "Fertilité masculine",
        path: "/pathologies/fertilite/",
        classes: "specialties-item"
      }
    ]
    return (
      <div className="author-specialties" id='author-speciality-anchor'>
        <Container>
          <Row>
            <div className="col-md-6 order-lg-1 order-2">
              <div className="specialties-list">
                <h2 className="specialties-title">Consultez avec le Dr Gilbert Bou Jaoudé ou un médecin sexologue de son équipe</h2>
                {pathologies.map((pathology, i) => (
                  <Link
                    to={pathology.path}
                    key={i}
                  >
                    <h3 className={`${pathology.classes}`}>{pathology.name}</h3>
                  </Link>
                ))}
                <Cta
                  className="new-primary-cta specialties-button"
                  text="Prendre rendez-vous"
                  path="/consulter/"
                />
              </div>
            </div>
            <div className="col-md-6 order-lg-2 order-1 specialties-image">
              <Image
                filename="author/doctors-team.png"
                alt="Dr Gilbert Bou Jaoudé et son équipe de médecins sexologues*"
                title="Dr Gilbert Bou Jaoudé et son équipe de médecins sexologues"
              />
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AuthorSpecialties;
