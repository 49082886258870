import Cta from "components/common/Cta";
import React from "react";
import "./styles.sass";
import VideoSection from "components/common/VideoSection";
import {Container, Row} from "react-bootstrap";

const IndexPage = ({ data }: any) => {

  return (
    <div className="program-presentation-component">
      <Container>
        <h2>Les programmes pour vous épanouir dans votre sexualité</h2>
        <div className="program-presentation-wrapper">
          {data.programs.map((program: any, index: number) => (
            <div className="program-presentation" key={`program-${index}`}>
              <div className="program-presentation-image">
                <VideoSection {...program.video}  />
              </div>
              <div className="program-presentation-text">
                <h3>{program.title}</h3>
                <div className="program-presentation-description">{program.description}</div>
                <Cta goToProgramSlug={`${program.slug}-program`} text="Accéder gratuitement au 1er épisode" className="new-secondary-black-cta" />
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default IndexPage;
