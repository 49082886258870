import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Image from 'components/common/utils/Image';
import VideoPlayer from 'components/common/VideoPlayer';
import StructuredVideo from 'components/common/meta/StructuredVideo';
import PlayBlack from 'images/icons/play-black.svg';
import './styles.sass';
import { trackVideoPlayed } from 'utils/segment'

class VideoThumbnail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleShow = () => {
    const { data } = this.props;
    this.setState({ showModal: true });
    trackVideoPlayed(data.title, data.video)
  };

  videoUrl = (url) => {
    if (url.startsWith("https://www.youtube.com/"))
      return `${url}&autoplay=1`
    return url;
  }

  render() {
    const { data, showPlayIcon } = this.props;
    return (
      <>
        <StructuredVideo {...data.youtube} />
        <div className="video-thumbnail" onClick={this.handleShow}>
          {data.filename ? (
            <Image className="video-thumbnail-image" filename={data.filename} alt={data.alt} title={data.title} />
          ) : (
            data.extFilename && (
              <img
                className="img-fluid video-thumbnail-image core-embed-image "
                src={data.extFilename}
                alt={data.alt}
                title={data.title}
              />
            )
          )}
          {showPlayIcon && (
            <div className="video-thumbnail-overlay">
              <PlayBlack width="80" />
            </div>
          )}
        </div>
        <Modal
          centered
          show={this.state.showModal}
          onHide={this.handleClose}
          size="xl"
        >
          <div className="expert-video-modal">
            <VideoPlayer video={this.videoUrl(data.video)} />
          </div>
        </Modal>
      </>
    );
  }
}

VideoThumbnail.defaultProps = {
  showPlayIcon: false,
};

export default VideoThumbnail;
